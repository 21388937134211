import type { AppProps } from 'next/app';
import { SWRConfig } from 'swr';
import '@/styles/globals.css';
export function SWRWrapper({
  children
}: React.PropsWithChildren) {
  function fetcher(url: string, ...args) {
    return fetch(url, ...args).then(res => res.json());
  }
  return <SWRConfig value={{
    fetcher
  }} data-sentry-element="SWRConfig" data-sentry-component="SWRWrapper" data-sentry-source-file="_app.tsx">{children}</SWRConfig>;
}
export default function App({
  Component,
  pageProps
}: AppProps) {
  return <>
      <SWRWrapper data-sentry-element="SWRWrapper" data-sentry-source-file="_app.tsx">
        <Component {...pageProps} data-sentry-element="Component" data-sentry-source-file="_app.tsx" />
      </SWRWrapper>
    </>;
}